
export class CINCListing {
	PDID: string;
	Lat: number;
	Lng: number;
	Price: number;
	IsFavorite: boolean;
	Address: string;
	CanShowAddress: boolean;
	CanShowOnMap: boolean;
}